import styled from '@emotion/styled';
import React from 'react';
import StyledSkewedSection from './skewed-section';
import { StyledH1 } from './_shared/styled-headings';

const StyledLogoSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledFactNumber = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  & > .number {
    margin-left: 2rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Playfair Display',serif;
  }

  & > .fact {
    position: relative;
    font-size: .75rem;
    margin-left: 2rem;
    opacity: .9;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    border-style: dotted;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -2rem;
      width: 1rem;
      height: 2px;
      background-color: var(--primary-color);
    }
  }
`;

const Facts = () => {
  return (
    <StyledSkewedSection angle={10}>
      <StyledH1>My Highlights</StyledH1>
      <StyledLogoSection>
        <StyledFactNumber>
          <span className="number">13</span>
          <span className="fact">International championships</span>
        </StyledFactNumber>
        <StyledFactNumber>
          <span className="number">9</span>
          <span className="fact">Top 3 placement awards</span>
        </StyledFactNumber>
        <StyledFactNumber>
          <span className="number">1000</span>
          <span className="fact">Satisfied students</span>
        </StyledFactNumber>
      </StyledLogoSection>
    </StyledSkewedSection>
  );
};

export default Facts;
